<template>
  <section class="pb-5 mb-2 mr-3 bg-white rounded-md shadow">
    <tabs
      :items="reportTabs"
      v-if="reportTabs.length > 0"
      v-on:tabChange="onTabChange"
    />

    <div
      key="reportView"
      class="flex flex-col w-full px-4 pt-8 pl-6 md:flex-row responisve-add-photo"
    >
      <div class="w-9/12">
        <template v-if="activeReport">
          <div class="flex items-center">
            <div class="flex">
              <span class="block bt-id">{{
                $t('components.tripManagement.details.report.reportID')
              }}</span>
              <span class="block ml-2 text-gray-500">
                <router-link
                  :to="{
                    name: 'ReportDetails',
                    params: { id: activeReport.id },
                  }"
                  class="text-oLink"
                >
                  {{ $truncate(activeReport.id, -5, '#') || '--' }}
                </router-link>
              </span>
            </div>
          </div>

          <div class="flex report-type">
            <div
              v-for="(part, partIndex) in activeReport.vehicle_parts"
              :key="`part-${partIndex}`"
              class="report-item place-content-center"
            >
              <span>{{ part }}</span>
            </div>
          </div>

          <div class="mt-4">
            <span class="">{{
              $t('components.tripManagement.details.report.reportDes')
            }}</span>
            <p class="report-des">
              {{
                $truncate(activeReport.description, 160, null, '...') || '--'
              }}
            </p>
          </div>

          <div class="mt-4" v-if="getReportImages.length > 0">
            <div class="flex mt-4 space-x-3 ">
              <img
                v-for="(image, imageIndex) in getReportImages"
                :key="`report-image-${imageIndex}`"
                :src="image.src"
                @click="image_viewing_index = imageIndex"
                class="object-cover w-32 h-32 rounded-lg cursor-pointer"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <XPlaceholder
            :key="`reportView`"
            :title="`Happy Riding :)`"
            :subtitle-one="`No report has been submitted so far.`"
            :subtitle-two="``"
            :img="`no-report`"
            :img-class="`h-32 w-40 -mb-2`"
          />
        </template>
      </div>

      <div class="w-3/12 ml-3">
        <div>
          {{ $t('components.tripManagement.details.report.parkingPhoto') }}
        </div>
        <div class="overflow-hidden parking-pic" v-if="detail.parking_pic">
          <img
            :src="detail.parking_pic"
            @click="image_viewing_index = 0"
            class="object-cover w-full h-full max-w-sm cursor-pointer"
          />
        </div>

        <div
          v-else
          class="flex items-center justify-start w-full h-full font-medium text-gray-500"
        >
          {{ $t('components.tripManagement.details.report.noParkingPhoto') }}
        </div>
      </div>
    </div>

    <CoolLightBox
      effect="fade"
      :items="getParkingImages"
      :index="image_viewing_index"
      :useZoomBar="true"
      @close="image_viewing_index = null"
    />

    <CoolLightBox
      effect="fade"
      :items="getReportImages"
      :index="image_viewing_index"
      :useZoomBar="true"
      @close="image_viewing_index = null"
    />
  </section>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import Tabs from '@/components/tabs/Tabs.vue'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'ReportBlock',
  components: {
    CoolLightBox,
    Tabs,
    XPlaceholder: () => import('@/components/placeholder/XPlaceholder.vue'),
  },
  props: ['detail'],
  data() {
    return {
      image_viewing_index: null,
      reportTabs: [],
      activeReport: null,
    }
  },
  mounted() {
    if (
      this.detail &&
      this.detail.trip_reports &&
      this.detail.trip_reports.length
    ) {
      for (let i = 0; i < this.detail.trip_reports.length; ++i) {
        if (i === 0) {
          this.activeReport = this.detail.trip_reports[i]
        }

        this.reportTabs.push({
          id: i,
          title: this.detail.trip_reports[i].report_categories,
          isActive: i === 0,
        })
      }
    }
  },
  computed: {
    getReportImages() {
      if (!this.activeReport) {
        return []
      }
      return this.activeReport.report_images.map((item) => ({
        src: item.report_pic,
      }))
    },
    getParkingImages() {
      return [{ src: this.detail.parking_pic }]
    },
  },
  methods: {
    onTabChange(tabId) {
      for (let i = 0; i < this.reportTabs.length; ++i) {
        if (tabId !== i) {
          this.reportTabs[i].isActive = false
        } else {
          this.reportTabs[i].isActive = true

          this.activeReport = this.detail.trip_reports[tabId]
        }
      }
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    },
    getCategoryBadgeColor(category) {
      const cat = this.getCategoryText(category)

      const categoryColorMap = {
        'others': 'blue',
        'damaged vehicle': 'red',
        'wrongly parked': 'orange',
        'payment': 'purple',
      }

      return cat in categoryColorMap ? categoryColorMap[cat] : 'blue'
    },
    getCategoryText(category) {
      if (Array.isArray(category)) {
        return (category = category[0].toLowerCase())
      }
      return category.toLowerCase() || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.parking-pic {
  margin-top: 10px;
  max-height: 220px;
  max-width: 150px;
  overflow: hidden;
  border-radius: 10px;
}
</style>
